import $ from 'jquery';

$(function () {

  const shareData = {
    title: $('h1').text(),
    url: window.location.href,
  };

  const resultPara = $('.result');

  $(document).on('click', '#share_button', function () {
    try {
      navigator.share(shareData).then(function () {});
      resultPara.text('La page a bien été partagée');
    } catch (error) {
      resultPara.text('Une erreur est survenue: ' + error);
    }
  });
});

window.addEventListener('load', () => {
  // Share on mobile
  const shareData = {
    title: document.querySelector('h1').textContent,
    url: window.location.href,
  };

  const button = document.querySelector('#shareButton');

  if(button) {
    button.addEventListener('click', () => {
      navigator.share(shareData).then(() => {});
    });
  }
});
