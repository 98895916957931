import $ from 'jquery';

// Animate callback
const callback = (entries) => {
  for (const entry of entries) {
    if (entry.isIntersecting) {
      entry.target.classList.add('visible');
    }
  }
};

// Animate text callback
const textCallback = (entries) => {
  for (const entry of entries) {
    if (entry.isIntersecting) {
      entry.target.classList.add('visible');
    }
  }
};

$(function () {
  // Base options
  const options = {
    threshold: 0.15,
  };

  const observer = new IntersectionObserver(callback, options);
  for (const element of document.querySelectorAll('.animate')) observer.observe(element);

  const textObserver = new IntersectionObserver(textCallback, options);
  for (const element of document.querySelectorAll('.animate-text p, .animate-text li , .animate-text h2 , .animate-text h3')) textObserver.observe(element);
});
