import $ from 'jquery';

$(function () {
  $(document).on('click', '#video-player', function() {
    $('#video-player, #lp_video_image').hide();
    $('.lp-video').addClass('show');

    const iframe_link = $('#iframe_link').attr('data-iframe');
    $('#lp-player').attr('src', iframe_link);
  });
});
