// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/tooltip';

import 'select2';

import './youtube';
import './sliders';
import './custom';
import './intersection';
import './copy';
import './share';
import './filters';
import './alert';
import './admin';
