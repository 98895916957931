import Swiper from 'swiper/bundle';

// home-working
new Swiper('.swiper-working', {
  slidesPerView: 1,
  spaceBetween: 30,
  centeredSlides: false,
  loop: false,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

// home-testimonial
new Swiper('.swiper-testimonial', {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  breakpoints: {
    980: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
});

// home-carousel
new Swiper('.swiper-carousel', {
  spaceBetween: 30,
  grabCursor: true,
  a11y: false,
  freeMode: true,
  speed: 5000,
  loop: true,
  slidesPerView: 'auto',
  autoplay: {
    delay: 0.5,
    disableOnInteraction: false,
  },
});

// home-goods
new Swiper('.swiper-goods', {
  slidesPerView: 1,
  spaceBetween: 30,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  breakpoints: {
    992: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1400: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  navigation: {
    nextEl: '.swiper-btn-next',
    prevEl: '.swiper-btn-prev',
  },
});

// home-news
new Swiper('.swiper-news', {
  slidesPerView: 1,
  spaceBetween: 30,
  centeredSlides: false,
  loop: false,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

// slider property
new Swiper('.swiper-property', {
  slidesPerView: 1,
  spaceBetween: 30,
  loop:true,
  navigation: {
    nextEl: '.swiper-next',
    prevEl: '.swiper-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

// slider announcing
new Swiper('.swiper-announcing', {
  spaceBetween: 30,
  breakpoints: {
    '@0.50': {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    '@1': {
      slidesPerView: 2.1,
      spaceBetween: 10,
    },
  },
});
