import $ from 'jquery';

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}

$(function () {
  // Ajout un collapse texte dans la page détail d'une annonce
  $('[data-bs-toggle="tooltip"]').tooltip();


  // restrict number phone form
  $(document).on('input', 'input[type="tel"]', function () {
    $(this).val($(this).val().replaceAll(/\D/g, ''));
  });

  // restrict number zip form
  $(document).on('input', '.zip input[type="text"]', function () {
    $(this).val($(this).val().replaceAll(/\D/g, ''));
  });

  // réduit le header au scroll
  const shrinkHeader = 60;
  $(window).on('scroll', function () {
    const scroll = getCurrentScroll();

    if (scroll >= shrinkHeader) {
      $('#header').addClass('smaller');
    } else {
      $('#header').removeClass('smaller');
    }
  });

  // Ajout un dropdown pour les sous menu en hover
  if ($(window).width() > 992) {
    $(document).on('click', '.nav-top-header .nav-item', function () {
      const link = $(this).find('.dropdown-toggle');
      if (link != undefined) {
        location.href = link.attr('href');
      }
    });
  }

  var button = $('#back-to-top');

  $(window).scroll(function() {
    if ($(window).scrollTop() > 500) {
      button.addClass('show');
    } else {
      button.removeClass('show');
    }
  });

  button.on('click', function(event) {
    event.preventDefault();
    $('html, body').animate({scrollTop:0}, '300');
  });
});
