import $ from 'jquery';
import Modal from 'bootstrap/js/dist/modal';

$(function () {
  $(document).on('submit', '#alert-form', function (event) {
    event.preventDefault();

    let criterions = new URLSearchParams();

    for (const value of $('#type_of_property').val()) {
      if (value !== 'tous') {
        criterions.append('type_of_property[]', value);
      }
    }

    for (const value of $('#type_of_sale').val()) {
      if (value !== 'tous') {
        criterions.append('type_of_sale[]', value);
      }
    }

    for (const value of $('#region').val()) {
      if (value !== 'tous') {
        criterions.append('region[]', value);
      }
    }

    $.ajax({
      url: '/wp-admin/admin-ajax.php',
      type: 'POST',
      data: {
        'action': 'create_alert',
        'firstname': $('#firstname').val(),
        'lastname': $('#lastname').val(),
        'phone': $('#phone').val(),
        'email': $('#email').val(),
        'criterions': criterions.toString(),
      },
      success: function () {
        $('#alert-form button').prop('disabled', true);
        $('#alert-form button').html('Votre alerte a été créée.');
      },
    });
  });

  $(document).on('click', '#go-to-alert', function (event) {
    event.preventDefault();
    const url = new URL(window.location);
    url.pathname = '/creez-une-alerte-email/'; // @TODO: make me dynamic plz
    window.location = url;
  });

  // Display a popup when unsubscribing
  const unsubscribeModal = document.querySelector('#unsubscribe-modal');
  if (unsubscribeModal) {
    const modal = new Modal(unsubscribeModal);
    modal.show();
  }
});
