/* global pagenow */

function initShortDescCounter() {
  const selector = 'textarea[data-id=short-desc]';
  const shortDesc = jQuery(selector);
  const textLength = shortDesc.val() ? shortDesc.val().length : 0;

  const html = `<div id="the-count">
    <span id="current">${textLength}</span>
    <span id="maximum">/ 1700</span>
  </div>`;

  jQuery(html).insertAfter(selector);

  jQuery(document).on('keyup', selector, function () {
    const characterCount = jQuery(this).val().length;
    const current = jQuery('#current');
    current.text(characterCount);
  });
}

jQuery(document).ready(() => {
  // Check if we're on the post edit page and it's the specific post type
  if (typeof pagenow !== 'undefined' && pagenow === 'property') {
    setTimeout(initShortDescCounter, 1000);
  }
});
